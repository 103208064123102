
.Transition-1 {
  transition: all 500ms cubic-bezier(0.67, 0.01, 0.34, 0.99) !important;
}

.Transition-2 {
  transition: all 600ms ease !important;
}

.Transition-hover {
  transition: all 300ms ease !important;
}

.Transition-opacity {
  animation: showOpacity 800ms ease !important;
}

@keyframes slideFromRight {
  0% {
    opacity: 0;
    right: -100px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes showOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}