.white-mode {
  --background-color0: #fff;
  --background-color1: #f3f5fc;
  --background-color2: #dfe2ec;

  --accent-color0: 255, 121, 0;
  --accent-color1: 12, 34, 84;

  --text-color0: #232c33;
  --text-color1: #394147;
  --text-color2: #4f565c;

  --navbar-color: rgba(255, 255, 255, 0.96);

  --box-shadow1: rgba(17, 12, 46, 0.15) 0px 28px 50px 0px;
}
