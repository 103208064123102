.customTable {
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
  width: 100%;
}
.customTable thead th {
  background-color: rgba(var(--accent-color1), 0.7);
  border: solid 0.1rem white;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  text-align: left;
}
.customTable tbody td {
  border: solid 0.1rem var(--background-color2);
  color: #333;

  font-size: 1rem;
  padding: 1rem;
}

.customTable tbody tr:hover {
  background-color: var(--background-color1);
}
