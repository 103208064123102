.swiperCard {
  cursor: pointer;
}

.swiperCard:hover {
  transform: scale(1.1);
}

.swiperCard:active {
  transition: none !important;
  transform: scale(1.05);
}