.loginButton {
  padding: 0.6rem 2rem;
  border-radius: 5rem;
  font-size: 1rem;
  color: var(--background-color0);
  font-weight: 600;
  background-color: rgba(var(--accent-color1), 0.6);
}

.loginButton:hover {
  color: var(--background-color0);
}

.loginButton:active {
  transform: translateY(0.1rem);
  transition: transform 0.01s ease;
}

.loginButton svg {
  font-size: 0rem;
  color: var(--text-color0);
  opacity: 0;
}

.loginButton:hover svg {
  font-size: 2rem;
  opacity: 1;
}

.loginButton .cardFlipEffect {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  border-radius: 2rem;
  opacity: 1;
  z-index: 1;
}

.loginButton:hover .cardFlipEffect {
  transform: rotateY(180deg);
  background-color: rgba(var(--accent-color0), 1) ;
}